import { Flex, Text, Button, Image, Input, Box, Select } from "theme-ui";
import { Header } from "../Components";
import { Device, ErrorBehaviour, FormInput, WizardProps, extractFormComponentDefaults } from "../models";
import * as Loader from "react-loader-spinner";
import { useEffect, useState } from "react";
import { ApiClient, HTTPError } from "../lib/api";
import { StepWizardChildProps } from "react-step-wizard";
import ReactMarkdown from "react-markdown";
import { ErrorText } from "../Components/ErrorText";

interface DeviceFormProps<T> {
  device: Device;
  onChange: (form: T) => void;
  currentForm: T;
}

interface PasswordPageProps extends StepWizardChildProps {
  device: Device;
  state: string;
  onClose: () => void;
  isMobile: boolean;
  token: string;
  env: string;
  showBackButton: boolean;
  region: string;
  errorBehaviour: ErrorBehaviour;
  setWizardProps: (data: WizardProps) => void;
}

const LogoHeader = ({ logo, name, description }) => (
  <Flex
    sx={{
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      mb: 4,
      mt: 20,
      px: 4,
    }}
  >
    <Image src={logo} width={60} sx={{ mr: 10 }} />
    <Flex sx={{ flexDirection: "column" }}>
      <Text sx={{ fontSize: 2, fontWeight: 600 }}>{name}</Text>
      <Text sx={{ fontSize: 0 }}>{description}</Text>
    </Flex>
  </Flex>
);

const FormElement = <T,>({
  el,
  device,
  onChange,
  currentForm,
}: {
  el: FormInput;
  device: Device;
  onChange: (form: T) => void;
  currentForm: T;
}) => {
  switch (el.type) {
    case "select":
      const names = el.names || {};
      return (
        <Select
          placeholder={device.name + " " + el.label}
          mb={3}
          name={el.label}
          type={el.type}
          defaultValue={el.value}
          onChange={(e) =>
            onChange({ ...currentForm, [el.key]: e.target.value })
          }
          width="100%"
        >
          {el.options.map((opt) => (
            <option key={opt} value={opt}>
              {names[opt] || opt}
            </option>
          ))}
        </Select>
      );

    case "text":
    case "password":
      return (
        <Input
          width="100%"
          placeholder={el.label}
          mb={3}
          name={el.label}
          type={el.type}
          onChange={(e) =>
            onChange({ ...currentForm, [el.key]: e.target.value })
          }
        />
      );

    default:
      console.error(`Invalid form input type ${el}`);
      return null;
  }
};

export function DeviceForm<T>({
  device,
  onChange,
  currentForm,
}: DeviceFormProps<T>) {
  return (
    <Flex sx={{ width: "100%", flexDirection: "column" }}>
      {device.form_components.inputs?.map((el, ix) => {
        return (
          <FormElement
            key={`${device.name}-${el.type}-${ix}`}
            el={el}
            device={device}
            onChange={onChange}
            currentForm={currentForm}
          />
        );
      })}
    </Flex>
  );
}

interface EmailAuthForm {
  email: string;
  region: string;
}

export const InfoPage: React.FunctionComponent<Partial<PasswordPageProps>> = ({
  device,
  state,
  token,
  onClose,
  env,
  showBackButton,
  errorBehaviour,
  region,
  setWizardProps,
  ...props
}) => {
  const [currentForm, setForm] = useState<EmailAuthForm>({
    email: "",
    region: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ error_type: string, status?: number } | null>(null);

  useEffect(() => {
    setForm(() => {
      // Reset the form value when the device slug is first set or is changed.
      return {
        email: "",
        region: "",
        ...extractFormComponentDefaults(device?.form_components),
      };
    });

  }, [device?.slug, device?.form_components, setForm]);

  const client = new ApiClient();

  const handleSubmit = async (info) => {
    if (Object.keys(info).length === 0) return null;
    setLoading(true);
    setError(null);
    try {
      const resp = await client.emailAuth(
        currentForm.email,
        device.slug,
        token,
        env,
        "email",
        region,
        currentForm.region
      );

      switch (resp.state) {
        case "success":
          setWizardProps({ linkCompleted: { redirectUrl: resp.redirect_url } });
          props.goToNamedStep("state");
          break;

        case "error":
          if (errorBehaviour === "redirect") {
            window.location.href = resp.redirect_url;

          } else {
            setLoading(false);
            setError({ error_type: resp.error_type });
          }

          break;
      }

    } catch (e) {

      if (e instanceof HTTPError) {
        if (e.json && "redirect_url" in e.json && errorBehaviour === "redirect") {
          window.location.href = e.json.redirect_url;
        }

        setLoading(false);
        setError({
          error_type: e.json && "error_type" in e.json ? e.json.error_type : "unknown",
          status: e.status,
        });

      } else {
        setLoading(false);
        setError({ error_type: "unknown", status: null });
      }
    }
  };

  if (!device || device.auth_type !== "email") {
    return null;
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: "100%",
        width: "100%",
      }}
      pt={4}
    >
      <Flex
        sx={{
          alignItems: "flex-start",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Header
          showBack={showBackButton}
          onBack={() => props.goToNamedStep("device")}
          onClose={onClose}
          hideExitButton={false}
        />
        <LogoHeader
          name={device.name}
          logo={device.logo}
          description={device.description}
        />
        <Box px={4} sx={{ width: "100%" }}>
          <Box sx={{
            
            width: "100%",
            mb: "20px",
            overflowY: "scroll",
            maxHeight: "14rem",

            border: "1px solid #a7a7a7",
            borderRadius: "8px",
            padding: "8px 16px 8px 16px",

            '&::-webkit-scrollbar': {
              "-webkit-appearance": "none",
              width: '0.4em',
              height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              outline: '1px solid slategrey',
              borderRadius: "8px",
            },

           }}>
            <Text sx={{ fontWeight: 800, fontSize: 3 }}>Instructions</Text>

            {device.form_components?.instructions?.map((el, i) => {
              return (
                <div style={{ fontSize: "14px", width: "100%" }} key={i}>
                  <ReactMarkdown key={i} children={el} />
                </div>
              );
            })}
          </Box>

          <DeviceForm<EmailAuthForm>
            device={device}
            onChange={setForm}
            currentForm={currentForm}
          />

          {error && <ErrorText error_type={error.error_type} />}
        </Box>
      </Flex>

      <Flex px={4} sx={{ width: "100%" }} pb={4}>
        <Button
          onClick={() => handleSubmit(currentForm.email)}
          sx={{ width: "100%", mt: 10 }}
        >
          <Box sx={{ height: 25 }}>
            {loading ? (
              <Loader.Oval color={"white"} width={25} height={25} wrapperStyle={{"justifyContent": "center"}}/>
            ) : (
              "Submit"
            )}
          </Box>
        </Button>
      </Flex>
    </Flex>
  );
};
